import React, { useState, useEffect } from "react";
import { Link, graphql } from "gatsby";
import { Row, Col, Button, Modal } from "react-bootstrap";
import Img from "gatsby-image";
import SEO from "../components/seo";
import ReactMarkdown from "react-markdown/with-html";
import Layout from "../components/Layout";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import ContactForm from "../components/forms/contact";

const ContactModal = ({ show, handleClose }) => (
  <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>Contact me regarding X-431 ADAS Mobile</Modal.Title>
    </Modal.Header>
    <div className="px-5 py-5">
      <ContactForm referrer="X-431 ADAS Mobile" />
    </div>
  </Modal>
);

export const AdasMobilePageTemplate = ({
  title,
  titleSEO,
  headerImage,
  targetsImage,
  optionsImage,
  content,
  priceText,
}) => {
  const [show, setShow] = useState(false);
  const [show_targetModal, toggle_targetModal] = useState(false);
  const [show_optionsModal, toggle_optionsModal] = useState(false);
  const TargetModal = () => {
    return (
      <Modal size="lg" show={show_targetModal} centered onHide={() => toggle_targetModal(false)}>
        <Modal.Body>
          <PreviewCompatibleImage
            imageInfo={{
              image: targetsImage,
              alt: "image",
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => toggle_targetModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const OptionsModal = () => {
    return (
      <Modal size="lg" show={show_optionsModal} centered onHide={() => toggle_optionsModal(false)}>
        <Modal.Body>
          <PreviewCompatibleImage
            imageInfo={{
              image: optionsImage,
              alt: "image",
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => toggle_optionsModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  };
  return (
    <Layout>
      <SEO title={titleSEO} />
      <ContactModal show={show} handleClose={() => setShow(false)} />
      <TargetModal />
      <OptionsModal />
      <Row className="d-flex flex-row justify-content-center align-items-center mx-0 mt-5" style={{ height: "90vh" }}>
        <Col xs={12} lg={6}>
          <PreviewCompatibleImage
            imageInfo={{
              image: headerImage,
              alt: "image",
              style: {
                height: "94vh",
                width: "auto",
                maxWidth: "100%",
                objectFit: "contain",
                left: "50%",
                transform: "translate(-50%, 0%)",
              },
            }}
          />
        </Col>
        <Col xs={12} lg={6} className="text-center text-lg-left">
          <h1 className="py-3 py-lg-0">{title}</h1>
          <p className="text-left text-md-center text-lg-left">
            <ul style={{ paddingLeft: "20px" }}>
              {/* <ReactMarkdown source={content} escapeHtml={false} linkTarget="_blank" /> */}
              <li>Lightweight and Mobile – Compact for easy storage </li>
              <li>Five-line & cross laser instrument ensure precision vehicle positioning.</li>
              <li>Level Micro Adjustment delivers accurate vehicle centering positioning</li>
              <li>
                Target Kit has an extensive vehicle coverage application.{" "}
                <Button variant="link" onClick={() => toggle_targetModal(true)}>
                  View Targets
                </Button>
              </li>
              <li>Concise User illustrations for simplified operation.</li>
            </ul>
          </p>
          <p>
            {priceText}
            <Button className="ml-3" size="sm" onClick={() => toggle_optionsModal(true)}>
              Included/Options
            </Button>
          </p>

          <Button onClick={() => setShow(!show)}>Contact Me</Button>
          <p className="pt-3">
            Or call: <a href="tel:2096020734">209-602-0734</a>
          </p>
        </Col>
      </Row>
    </Layout>
  );
};

const AdasMobilePage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout>
      <AdasMobilePageTemplate
        title={frontmatter.title}
        titleSEO={frontmatter.titleSEO}
        content={frontmatter.content}
        priceText={frontmatter.priceText}
        headerImage={frontmatter.headerImage}
        targetsImage={frontmatter.targetsImage}
        optionsImage={frontmatter.optionsImage}
      />
    </Layout>
  );
};

export default AdasMobilePage;

export const pageQuery = graphql`
  query AdasMobilePageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "adas-mobile-page" } }) {
      frontmatter {
        title
        titleSEO
        content
        priceText
        headerImage {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        targetsImage {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        optionsImage {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
